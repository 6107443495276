import { INetwork } from '@/state/network/types';
import createAxiosInstance from './http-client';
import { transformNetwork } from '@/state/network/helper';
import { TC_BRIDGES_API_URL } from '@/configs';
// import { transformNetwork } from '@/state/network/helper';

const apiClient = createAxiosInstance({ baseURL: TC_BRIDGES_API_URL });

const API_PATH = '/api/networks';

export const getNetworkListAPI = async (): Promise<INetwork[]> => {
  try {
    const response: INetwork[] = await apiClient.get(`${API_PATH}`);
    let networkList: INetwork[] = [];
    if (response) {
      networkList = response;
    }
    // console.log('[getNetworkListAPI] response: ', response);
    return transformNetwork(networkList);
  } catch (error: any) {
    console.log('[getNetworkListAPI] ERROR: ', error);
    throw error;
  }
};
