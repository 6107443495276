import { createSlice } from '@reduxjs/toolkit';

export interface LoadingState {
  isLoading: boolean;
  message?: string;
}

export const initialState: LoadingState = {
  isLoading: false,
  message: undefined,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading(
      state,
      { payload }: { payload: { flag: boolean; message?: string } },
    ) {
      state.isLoading = payload.flag;
      state.message = payload.message;
    },
  },
});
export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
