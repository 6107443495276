import { apiClient } from '@/services';
import {
  FeeRateName,
  ICollectedUTXOResp,
  IFeeRate,
  IPendingUTXO,
} from '@/interfaces/api/bitcoin';
import BigNumber from 'bignumber.js';
import * as TC_SDK from 'trustless-computer-sdk';
import { API_BLOCKSTREAM } from '@/configs';

const WALLETS_API_PATH = '/api/wallets';

// Collected UTXO
export const getCollectedUTXOLegacy = async (
  btcAddress: string,
  tcAddress: string,
): Promise<ICollectedUTXOResp | undefined> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const collected: any = await apiClient.get<ICollectedUTXOResp>(
      `${WALLETS_API_PATH}/${btcAddress}`,
    );
    const incomingUTXOs: TC_SDK.UTXO[] = [];
    const pendingUTXOs = await getPendingUTXOs(btcAddress);
    for (const utxo of pendingUTXOs) {
      for (let index = 0; index < utxo.vout.length; index++) {
        const vout = utxo.vout[index];
        if (
          vout.scriptpubkey_address.toLowerCase() === btcAddress.toLowerCase() &&
          vout.value
        ) {
          // append incoming utxo
          incomingUTXOs.push({
            tx_hash: utxo.txid,
            tx_output_n: index,
            value: new BigNumber(vout.value),
          });
        }
      }
    }
    const tempUTXOs = [...(collected?.txrefs || []), ...incomingUTXOs];
    let utxos;
    try {
      utxos = await TC_SDK.aggregateUTXOs({
        tcAddress: tcAddress,
        btcAddress: btcAddress,
        utxos: [...tempUTXOs],
      });
    } catch (e) {
      utxos = [...tempUTXOs];
    }
    return {
      ...collected,
      txrefs: utxos || [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;
  } catch (err) {
    console.log(err);
  }
};

export const getCollectedUTXO = async (
  btcAddress: string,
  tcAddress: string,
): Promise<ICollectedUTXOResp | undefined> => {
  try {
    const collected: any = await apiClient.get<ICollectedUTXOResp>(
      `${WALLETS_API_PATH}/${btcAddress}`,
    );
    const tempUTXOs = [...(collected?.txrefs || [])];
    let utxos;
    try {
      utxos = await TC_SDK.aggregateUTXOs({
        tcAddress: tcAddress,
        btcAddress: btcAddress,
        utxos: [...tempUTXOs],
      });
    } catch (e) {
      utxos = [...tempUTXOs];
    }
    return {
      ...collected,
      txrefs: utxos || [],
    } as any;
  } catch (err) {
    console.log(err);
  }
};

export const getPendingUTXOs = async (
  btcAddress: string,
): Promise<IPendingUTXO[]> => {
  let pendingUTXOs = [];
  if (!btcAddress) return [];
  try {
    // const res = await fetch(
    //   `https://blockstream.info/api/address/${btcAddress}/txs`,
    // ).then((res) => {
    //   return res.json();
    // });

    const res = await fetch(`${API_BLOCKSTREAM}/address/${btcAddress}/txs`).then(
      (res) => {
        return res.json();
      },
    );
    pendingUTXOs = (res || []).filter(
      (item: IPendingUTXO) => !item.status.confirmed,
    );
  } catch (err) {
    return [];
  }
  return pendingUTXOs;
};

export const getFeeRate = async (): Promise<IFeeRate> => {
  try {
    const res = await fetch('https://mempool.space/api/v1/fees/recommended');
    const fee: IFeeRate = await res.json();
    if (fee[FeeRateName.fastestFee] <= 10) {
      return {
        [FeeRateName.fastestFee]: 15,
        [FeeRateName.halfHourFee]: 10,
        [FeeRateName.hourFee]: 5,
      };
    }
    return fee;
  } catch (err: unknown) {
    console.log(err);
    return {
      [FeeRateName.fastestFee]: 25,
      [FeeRateName.halfHourFee]: 20,
      [FeeRateName.hourFee]: 15,
    };
  }
};

export const getTokenRate = async (): Promise<number> => {
  return 2;
};
