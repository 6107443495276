import { TOKEN_BLACKLIST, TOKEN_STATUS_BLACKLIST } from '@/configs';
import { Token } from './types';
import store from '@/state';

const CONFIG_FILTER = {
  STATUS: true,
  SYMBOL: true,
  NETWORK: true,
};

export const filterSymbol = (tokenList: Token[]): Token[] => {
  return (
    tokenList.filter((token) => {
      const symbol = token.symbol?.toUpperCase() || '';
      //Filter Token by Symbol in BlackList
      if (TOKEN_BLACKLIST.includes(symbol)) {
        return false;
      }
      return true;
    }) || []
  );
};

export const filterStatus = (tokenList: Token[]): Token[] => {
  return (
    tokenList.filter((token) => {
      if (
        token.status !== undefined &&
        TOKEN_STATUS_BLACKLIST.includes(token.status)
      ) {
        return false;
      }
      return true;
    }) || []
  );
};

export const filterNetwork = (tokenList: Token[]): Token[] => {
  const networkList = store.getState().networkReducer.networkList || [];
  const networkNameList = networkList.map((item) => item.networkName) || [];
  return tokenList.filter((token) => networkNameList.includes(token.network));
};

// ------------------------------------------------------
// Filter tokens before using throught app
// ------------------------------------------------------
export const filterManager = (tokenList: Token[]) => {
  if (CONFIG_FILTER.SYMBOL) tokenList = filterSymbol(tokenList);
  if (CONFIG_FILTER.STATUS) tokenList = filterStatus(tokenList);
  if (CONFIG_FILTER.NETWORK) tokenList = filterNetwork(tokenList);
  return tokenList;
};
