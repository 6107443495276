import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const ModalStyled = styled(Modal)`
  &.modal {
    --bs-modal-color: rgb(00, 00, 00);
  }

  .modal-content {
    padding: 15px;
    background: #1b1e26;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;
  }

  .modal-header {
    border-bottom: 0.5px solid #353945;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  border-radius: 10px;

  .wallet-link {
    font-weight: 600;
    text-decoration: underline;
    :hover {
      opacity: 0.8;
    }
  }
`;

export { ModalStyled, Container };
