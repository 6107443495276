import { createSlice } from '@reduxjs/toolkit';
import { PREDIX, actionCreators, fetchNetworks } from './actions';
import { NetworkReducerState } from './types';

export const initialState: NetworkReducerState = {
  networkList: [],
  isFetching: true,
  isFetched: false,
};

const slice = createSlice({
  name: PREDIX,
  initialState,
  reducers: {
    ...actionCreators,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNetworks.pending, (state) => {
        state.networkList = [];
        state.isFetching = true;
      })
      .addCase(fetchNetworks.fulfilled, (state, action) => {
        state.networkList = action.payload;
        state.isFetching = false;
        state.isFetched = true;
      })
      .addCase(fetchNetworks.rejected, (state, action) => {
        state.networkList = [];
        state.isFetching = false;
        state.isFetched = true;
        state.error = action.error;
      });
  },
});

export const networkActionCreators = slice.actions;
export default slice.reducer;
