import { createSlice } from '@reduxjs/toolkit';

interface RouterState {
  routerParams: {
    tab?: string;
    tokenSymbol?: string;
    from?: string; //From Network
    to?: string; //From Network
    token?: string;
  };
}

const initialState: RouterState = {
  routerParams: {
    tab: 'Deposit',
    tokenSymbol: 'BTC',
    from: 'bitcoin',
    to: 'trustless-computer',
  },
};

const routerSlice = createSlice({
  name: 'routerNavigator',
  initialState,
  reducers: {
    setRouterParams(state, { payload }) {
      state.routerParams = payload;
    },
  },
});

export const { setRouterParams } = routerSlice.actions;
export default routerSlice.reducer;
