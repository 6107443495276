import { combineReducers } from 'redux';
import connection from './connection/reducer';
import user from './user/reducer';
import routerReducer from './navgiator/reducer';

// Reducer from Containers
import tokensReducer from './tokens/reducer';
import web3ReactReducer from './web3React/reducer';
import loadingReducer from './loading/reducer';
import networkReducer from './network/reducer';

import { default as formBridgeReducer } from '../containers/FormBridge/state/reducer';

const reducers = combineReducers({
  routerReducer,
  web3ReactReducer,
  formBridgeReducer,
  user,
  connection,
  tokensReducer,
  loadingReducer,
  networkReducer,
});

export default reducers;
