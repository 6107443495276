import { createAsyncThunk } from '@reduxjs/toolkit';
// import { transformNetwork } from './helper';

import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { INetwork, NetworkReducerState } from './types';
import { getNetworkListAPI } from '@/services/networks';

export const PREDIX = 'NETWORKS';

const setNetworks: CaseReducer<NetworkReducerState, PayloadAction<INetwork[]>> = (
  state,
  action,
) => {
  state.networkList = action.payload;
};

const fetchNetworks = createAsyncThunk(`${PREDIX}/fetchNetworks`, async () => {
  const dataList = await getNetworkListAPI();
  return dataList;
});

const actionCreators = {
  setNetworks,
};

// Export Pure Actions
export { actionCreators };

// Export Async Actions
export { fetchNetworks };
