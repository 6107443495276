import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useAppDispatch } from '@/state/hooks';
import {
  resetUser,
  updateEVMWallet,
  updateSelectedWallet,
  updateTaprootWallet,
} from '@/state/user/reducer';
import { getConnection } from '@/connection';
import { useSelector } from 'react-redux';
import { getUserSelector } from '@/state/user/selector';
import { generateNonceMessage, verifyNonceMessage } from '@/services/auth';
import {
  getAccessToken,
  clearAccessTokenStorage,
  setAccessToken,
} from '@/utils/auth-storage';
import Web3 from 'web3';
import { provider } from 'web3-core';
import { getCurrentProfile } from '@/services/profile';
import useAsyncEffect from 'use-async-effect';
import { useRouter } from 'next/router';
import { ROUTE_PATH } from '@/constants/route-path';
import bitcoinStorage from '@/utils/bitcoin-storage';
import * as TC_SDK from 'trustless-computer-sdk';
import { isProduction } from '@/utils/commons';
import { toast } from 'react-hot-toast';
import debounce from 'lodash/debounce';
import AccountErrorModal from '@components/AccountErrorModal';
import { parseError } from '@utils/errorHelper';

export interface IWalletContext {
  onDisconnect: () => Promise<void>;
  requestBtcAddress: () => Promise<void>;
  onConnect: () => Promise<string | null>;
  onConnectMetamask: () => Promise<string | null>;
}

const initialValue: IWalletContext = {
  onDisconnect: () => new Promise<void>((r) => r()),
  requestBtcAddress: () => new Promise<void>((r) => r()),
  onConnect: () => new Promise<null>((r) => r(null)),
  onConnectMetamask: () => new Promise<null>((r) => r(null)),
};

export const WalletContext = React.createContext<IWalletContext>(initialValue);

export const WalletProvider: React.FC<PropsWithChildren> = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const { connector, provider, account } = useWeb3React();
  const [isConnectError, setIsConnectError] = React.useState(false);

  const dispatch = useAppDispatch();
  const user = useSelector(getUserSelector);
  const router = useRouter();

  const disconnect = React.useCallback(async () => {
    console.log('disconnecting...');
    console.log('user', user);
    if (user?.walletAddress) {
      bitcoinStorage.removeUserTaprootAddress(user?.walletAddress);
    }
    if (connector && connector.deactivate) {
      await connector.deactivate();
    }
    await connector.resetState();
    clearAccessTokenStorage();
    dispatch(resetUser());
  }, [connector, dispatch, user]);

  const connect = React.useCallback(async () => {
    const connection = getConnection(connector);
    if (!connection) {
      throw new Error('Get connection error.');
    }
    await connection.connector.activate();
    const addresses = await connector.provider?.request({
      method: 'eth_accounts',
    });
    if (addresses && Array.isArray(addresses)) {
      const evmWalletAddress = addresses[0];
      const data = await generateNonceMessage({
        address: evmWalletAddress,
      });
      if (data) {
        const web3Provider = new Web3(window.ethereum as provider);
        const signature = await web3Provider.eth.personal.sign(
          Web3.utils.fromUtf8(data),
          evmWalletAddress,
          '',
        );
        const { token: accessToken, refreshToken } = await verifyNonceMessage({
          address: evmWalletAddress,
          signature: signature,
        });
        console.log('signature', signature);
        setAccessToken(accessToken, refreshToken);
        dispatch(updateEVMWallet(evmWalletAddress));
        dispatch(updateSelectedWallet({ wallet: connection.type }));
        return evmWalletAddress;
      }
    }
    return null;
  }, [dispatch, connector, provider]);

  const requestBtcAddress = async (): Promise<void> => {
    await TC_SDK.actionRequest({
      method: TC_SDK.RequestMethod.account,
      redirectURL: window.location.origin + window.location.pathname,
      target: '_self',
      isMainnet: isProduction(),
    });
    // window.location.href = `${TC_WEB_URL}/?function=request&method=account&redirectURL=${window.location.origin + window.location.pathname}`;
  };

  useEffect(() => {
    if (user?.walletAddress && !user.walletAddressBtcTaproot) {
      const taprootAddress = bitcoinStorage.getUserTaprootAddress(
        user?.walletAddress,
      );
      if (!taprootAddress) return;
      dispatch(updateTaprootWallet(taprootAddress));
    }
  }, [user, dispatch]);

  const onLogin = async () => {
    const accessToken = getAccessToken();
    if (accessToken && connector) {
      try {
        const connection = getConnection(connector);
        if (!connection) {
          throw new Error('Get connection error.');
        }

        try {
          await connection.connector.activate();
        } catch (err) {
          console.log(err);
        }

        // if (chainId !== SupportedChainId.TRUSTLESS_COMPUTER) {
        //   await switchChain(SupportedChainId.TRUSTLESS_COMPUTER);
        // }
        const { walletAddress } = await getCurrentProfile();
        if (!!account && account.toLowerCase() !== walletAddress.toLowerCase()) {
          clearAccessTokenStorage();
          setIsConnectError(true);
          dispatch(resetUser());
          return;
        }
        dispatch(updateEVMWallet(walletAddress));
        dispatch(updateSelectedWallet({ wallet: 'METAMASK' }));
      } catch (err: unknown) {
        clearAccessTokenStorage();
        console.log(err);
      }
    }
  };

  const debounceOnLogin = React.useCallback(debounce(onLogin, 500), [
    dispatch,
    connector,
    account,
  ]);

  useAsyncEffect(debounceOnLogin, [dispatch, connector, account]);

  useEffect(() => {
    const handleAccountsChanged = async () => {
      await disconnect();
      // router.push(`${ROUTE_PATH.CONNECT_WALLET}?next=${ROUTE_PATH.HOME}`);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    };

    if (window.ethereum) {
      Object(window.ethereum).on('accountsChanged', handleAccountsChanged);
    }
  }, [disconnect]);

  const onConnectedTrustlessWallet = () => {
    const { tcAddress, tpAddress } = router.query as {
      tcAddress: string;
      tpAddress: string;
    };
    if (
      !!tcAddress &&
      !!account &&
      account.toLowerCase() !== tcAddress.toLowerCase()
    ) {
      setIsConnectError(true);
      router.replace(`${ROUTE_PATH.CONNECT_WALLET}?next=${window.location.origin}`);
      return;
    }
    if (tpAddress && !!account) {
      dispatch(updateTaprootWallet(tpAddress));
      bitcoinStorage.setUserTaprootAddress(tcAddress, tpAddress);
      router.push(ROUTE_PATH.HOME);
    }
  };

  const onConnectMetamask = async () => {
    if (connector) {
      try {
        const connection = getConnection(connector);
        if (!connection) {
          throw new Error('Get connection error.');
        }
        await connection.connector.activate();
      } catch (error) {
        toast.error(parseError(error));
      }
    }
    return null;
  };

  const debounceOnConnectedTrustlessWallet = React.useCallback(
    debounce(onConnectedTrustlessWallet, 1000),
    [router, account],
  );

  useEffect(() => {
    debounceOnConnectedTrustlessWallet();
  }, [router, account]);

  const contextValues = useMemo((): IWalletContext => {
    return {
      onDisconnect: disconnect,
      onConnect: connect,
      requestBtcAddress,
      onConnectMetamask,
    };
  }, [disconnect, connect, requestBtcAddress]);

  return (
    <WalletContext.Provider value={contextValues}>
      {children}
      <AccountErrorModal
        isShow={isConnectError}
        onHide={() => setIsConnectError(false)}
      />
    </WalletContext.Provider>
  );
};
