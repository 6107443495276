import { createSlice } from '@reduxjs/toolkit';
import { PREDIX, actionCreators, fetchToken } from './actions';
import { groupTokenByNetworkAndMap } from './helper';
import { Token, TokenMapType } from './types';

export const tokenGroupNetworkInit = {};

export type TokenState = {
  tokenMap: TokenMapType;
  tokenList?: Token[];
  tokenGroupNetwork?: {
    [key in string]: Token[];
  };
  isFetching: boolean;
  isFetched: boolean;
  error?: any;
};

export const initialState: TokenState = {
  tokenMap: {},
  tokenList: [],
  tokenGroupNetwork: tokenGroupNetworkInit,
  isFetching: true,
  isFetched: false,
  error: undefined,
};

const tokenSlice = createSlice({
  name: PREDIX,
  initialState,
  reducers: {
    ...actionCreators,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchToken.pending, (state) => {
        state.tokenList = [];
        state.tokenGroupNetwork = tokenGroupNetworkInit;
        state.isFetching = true;
        state.isFetched = false;
      })
      .addCase(fetchToken.fulfilled, (state, action) => {
        state.tokenList = action.payload;
        const { tokenGroupNetwork, tokenMap } = groupTokenByNetworkAndMap(
          action.payload,
        );

        state.tokenGroupNetwork = tokenGroupNetwork;
        state.tokenMap = tokenMap;

        state.isFetching = false;
        state.isFetched = true;
      })
      .addCase(fetchToken.rejected, (state, action) => {
        state.tokenList = [];
        state.tokenGroupNetwork = tokenGroupNetworkInit;
        state.isFetching = false;
        state.isFetched = true;
        state.error = action.error;
      });
  },
});

export const tokenActionCreators = tokenSlice.actions;
export default tokenSlice.reducer;
