import { uniqBy } from 'lodash';
import { INetwork } from './types';

export const transformNetwork = (networkList: INetwork[]): INetwork[] => {
  const wrapperList =
    networkList.map((item) => {
      const wrapper: INetwork = {
        ...item,
      };
      return wrapper;
    }) || [];

  return uniqBy(wrapperList, 'networkName');
};
