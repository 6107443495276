import { isProduction } from './commons';

export const DEBUG = !isProduction();

type LoggerTags = 'API' | 'LOG' | 'INFO' | 'DEBUG' | 'WARN' | 'ERROR';

const TagStyleDecorator = {
  API: [
    'color: white',
    'background: black',
    'font-size: 16px',
    'border: 1px solid blue',
    'padding: 10px',
  ].join(';'),

  LOG: [
    'color: white',
    'background: black',
    'font-size: 16px',
    'border: 1px solid yellow',
    'padding: 10px',
  ].join(';'),

  INFO: [
    'color: white',
    'background: black',
    'font-size: 16px',
    'border: 1px solid yellow',
    'padding: 10px',
  ].join(';'),

  DEBUG: [
    'color: white',
    'background: black',
    'font-size: 16px',
    'border: 1px solid white',
    'padding: 10px',
  ].join(';'),

  WARN: [
    'color: white',
    'background: yellow',
    'font-size: 18px',
    'border: 1px solid white',
    'padding: 10px',
  ].join(';'),

  ERROR: [
    'color: white',
    'background: black',
    'font-size: 16px',
    'border: 3px solid red',
    'padding: 10px',
  ].join(';'),
};

const createLogger =
  (tag: LoggerTags = 'INFO') =>
  (message = '', ...rest: any[]) => {
    if (!DEBUG) return;
    if (!rest) return;
    if (Array.isArray(rest) && rest.length === 1 && typeof rest[0] === 'string') {
      console.log(
        '%c[%s]%s%s',
        TagStyleDecorator[tag],
        tag.toString(),
        message,
        rest[0] ?? '',
      );
    } else {
      console.log(
        '%c[%s]%s%O',
        TagStyleDecorator[tag],
        tag.toString(),
        message,
        rest[0] ?? '',
      );
    }
  };

export const logger = createLogger('LOG');
export default createLogger;
