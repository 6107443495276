import { TC_BRIDGES_API_URL } from '@/configs';
import {
  EstimateWithdrawFeeData,
  EstimateWithdrawFeePayload,
} from '@/interfaces/api/withdraw';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import createAxiosInstance from './http-client';

const apiClient = createAxiosInstance({ baseURL: TC_BRIDGES_API_URL });

const API_PATH = '/api/estimate-withdraw-fee';

export const estimateWithdrawFeeAPI = async (
  payload: EstimateWithdrawFeePayload,
): Promise<EstimateWithdrawFeeData> => {
  try {
    const response = await apiClient.post(`${API_PATH}`, payload);
    console.log('[estimateWithdrawFeeAPI] response: ', response);
    return Object(camelCaseKeys(response));
  } catch (error: any) {
    console.log('[estimateWithdrawFeeAPI] error: ', error);
    throw error;
  }
};
