import { NETWORK_SUPPORTING } from '@/constants/network';
import {
  GenerateDepositData,
  GenerateDepositPayload,
} from '@/interfaces/api/deposit';
import { generateDepositDataAPI } from '@/services/deposit';
import { estimateWithdrawFeeAPI } from '@/services/withdraw';
import { Token } from '@/state/tokens/types';
import { CaseReducer, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { FormBridgeState } from './reducer';
import { FormType } from './types';

import { getFormTypeByNetwork } from '@/containers/FormBridge/FormBridge.utils';
import { NetworkName } from '@/state/network/types';
import { EstimateWithdrawFeePayload } from '@/interfaces/api/withdraw';

export const PREDIX = 'FormBridge';

const setLoading: CaseReducer<FormBridgeState, PayloadAction<boolean>> = (
  state,
  action,
) => {
  state.isLoading = action.payload;
};

const setFromNetwork: CaseReducer<FormBridgeState, PayloadAction<NetworkName>> = (
  state,
  action,
) => {
  // NEW fromNetwork (data from action payload)
  const newFromNetwork = action.payload;

  // OLD toNetwork
  const oldToNetwork = state.toNetworkSelected;

  let newToNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1 as NetworkName;
  if (oldToNetwork) {
    newToNetwork = oldToNetwork;
  }

  // Update Network
  state.formType = getFormTypeByNetwork(newFromNetwork);
  state.fromNetworkSelected = newFromNetwork;
  state.toNetworkSelected = newToNetwork;
  state.fromBalance = '0';
  state.error = undefined;
};

const setFromTokenSelected: CaseReducer<
  FormBridgeState,
  PayloadAction<Token | undefined>
> = (state, action) => {
  state.fromTokenSelected = action.payload;
  state.fromBalance = '0';
  state.error = undefined;
};

const setFromInputAmount: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.fromInputAmount = action.payload;
  state.error = undefined;
};

const setFromBalance: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.fromBalance = action.payload;
};

const setMaxErrorMessage: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.error = action.payload;
};

const setAllowanceAmount: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.allowanceAmount = action.payload;
};

const setToBalance: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.toBalance = action.payload;
  state.error = undefined;
};

const setToNetwork: CaseReducer<FormBridgeState, PayloadAction<NetworkName>> = (
  state,
  action,
) => {
  state.toNetworkSelected = action.payload;
  const newToNetwork = action.payload;

  // OLD fromNetwork
  const oldFromNetwork = state.fromNetworkSelected;

  let newFromNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1 as NetworkName;

  // Update ToNetwork
  if (!oldFromNetwork || oldFromNetwork === newToNetwork) {
    switch (newToNetwork) {
      case NETWORK_SUPPORTING.BITCOIN:
      case NETWORK_SUPPORTING.ORDINALS:
      case NETWORK_SUPPORTING.ETHEREUM:
        newFromNetwork = NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
        break;
      case NETWORK_SUPPORTING.TRUSTLESS_LAYER1:
        newFromNetwork = NETWORK_SUPPORTING.BITCOIN;
        break;
    }
  } else {
    newFromNetwork = oldFromNetwork;
  }

  // Update FormType
  state.formType = getFormTypeByNetwork(newFromNetwork);

  // Update Network
  state.fromNetworkSelected = newFromNetwork;
  state.toNetworkSelected = newToNetwork;
  // state.fromBalance = '0';
};

const setToTokenSelected: CaseReducer<
  FormBridgeState,
  PayloadAction<Token | undefined>
> = (state, action) => {
  state.toTokenSelected = action.payload;
  state.error = undefined;
};

const setToInputAmount: CaseReducer<
  FormBridgeState,
  PayloadAction<string | undefined>
> = (state, action) => {
  state.toInputAmount = action.payload;
  state.error = undefined;
};

const setFormType: CaseReducer<FormBridgeState, PayloadAction<FormType>> = (
  state,
  action,
) => {
  state.formType = action.payload;
};

const setGenerateDepositData: CaseReducer<
  FormBridgeState,
  PayloadAction<GenerateDepositData | undefined>
> = (state, action) => {
  state.generateDepositData = action.payload;
  state.error = undefined;
};

const swapAction: CaseReducer<FormBridgeState, PayloadAction> = (state) => {
  const {
    fromNetworkSelected: oldFromNetworkSelected,
    toNetworkSelected: oldToNetworkSelected,
  } = state;

  const newFromNetworkSelected = oldToNetworkSelected;
  const newToNetworkSelected = oldFromNetworkSelected;

  state.fromNetworkSelected = newFromNetworkSelected;
  state.toNetworkSelected = newToNetworkSelected;
  state.error = undefined;

  if (newFromNetworkSelected) {
    // Update FormType
    state.formType = getFormTypeByNetwork(newFromNetworkSelected);
  }
};

const setFormInstance: CaseReducer<FormBridgeState, PayloadAction<any>> = (
  state,
  action,
) => {
  state.formInstance = action.payload;
  state.error = undefined;
};

const clearGenerateDepositData: CaseReducer<FormBridgeState, PayloadAction> = (
  state,
) => {
  state.generateDepositData = undefined;
  state.generateDepositDataLoading = false;
  state.generateDepositError = undefined;
};

const clearEstimateWithdrawData: CaseReducer<FormBridgeState, PayloadAction> = (
  state,
) => {
  state.estimateWithdrawData = undefined;
  state.estimateWithdrawLoading = false;
  state.estimateWithdrawError = undefined;
};

const setFeeBurnNativeToken: CaseReducer<FormBridgeState, PayloadAction<string>> = (
  state,
  action,
) => {
  state.feeBurnNativeToken = action.payload;
};

const actionCreators = {
  setLoading,

  setFromNetwork,
  setFromTokenSelected,
  setFromInputAmount,
  setFromBalance,

  setToNetwork,
  setToTokenSelected,
  setToInputAmount,
  setToBalance,
  setMaxErrorMessage,

  setFormType,
  setGenerateDepositData,

  swapAction,

  setAllowanceAmount,
  setFormInstance,
  clearGenerateDepositData,
  clearEstimateWithdrawData,

  //Burn Natiev Token
  setFeeBurnNativeToken,
};

const fetchGenerateDepositData = createAsyncThunk(
  `${PREDIX}/fetchGenerateDepositData`,
  async (payload: GenerateDepositPayload) => {
    try {
      const data = await generateDepositDataAPI(payload);
      return data;
    } catch (error: any) {
      throw error;
    }
  },
);

const fetchEstimateWithdrawData = createAsyncThunk(
  `${PREDIX}/fetchEstimateWithdrawData`,
  async (payload: EstimateWithdrawFeePayload) => {
    try {
      const data = await estimateWithdrawFeeAPI(payload);
      return data;
    } catch (error: any) {
      throw error;
    }
  },
);

// Export Pure Actions
export { actionCreators };
// Export Async Actions
export { fetchGenerateDepositData, fetchEstimateWithdrawData };
