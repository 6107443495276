import { TOKEN_BLACKLIST, TOKEN_STATUS_BLACKLIST } from '@/configs';
import { orderBy } from 'lodash';
import {
  GroupTokenByNetworkAndMap,
  Token,
  TokenGroupByNetworkType,
  TokenMapType,
  TokenType,
} from './types';

export const groupTokenByNetworkAndMap = (
  tokenList: Token[],
): GroupTokenByNetworkAndMap => {
  const tokenMap: TokenMapType = {};
  const tokenGroupNetwork: TokenGroupByNetworkType = {};

  tokenList.map((token) => {
    const network = token?.network ?? undefined;

    if (network) {
      if (!tokenGroupNetwork[network]) {
        tokenGroupNetwork[network] = [];
      }
      tokenGroupNetwork[network].push(token);
    }
    const key = `[${token.network}]-[${token.tokenID}]`;
    tokenMap[key] = token;
  });

  const networkList = Object.keys(tokenGroupNetwork);

  networkList.map((network) => {
    if (tokenGroupNetwork[network] && tokenGroupNetwork[network].length < 1) {
      delete tokenGroupNetwork[network];
    }
  });

  return { tokenGroupNetwork, tokenMap };
};

export const sortToken = (tokenList: Token[]): Token[] => {
  const tokenListSorted = orderBy(
    tokenList,
    (token) => [token.type === 'NATIVE', !!token.symbol],
    ['desc', 'asc'],
  );
  return tokenListSorted;
};

export const filterTokens = (tokenList: Token[]): Token[] => {
  console.log('==================TOKEN_BLACKLIST==================');
  console.log(TOKEN_BLACKLIST);
  console.log('====================================');

  console.log('==================TOKEN_STATUS_BALCKLIST==================');
  console.log(TOKEN_STATUS_BLACKLIST);
  console.log('====================================');
  // ------------------------------------------------------
  // Filter tokens before using throught app
  // ------------------------------------------------------

  return (
    tokenList.filter((token) => {
      const symbol = token.symbol?.toUpperCase() || '';
      // ------------------------------------------------------
      // RULE(s)
      // ------------------------------------------------------

      //Filter Token by Symbol in BlackList
      if (TOKEN_BLACKLIST.includes(symbol)) {
        return false;
      }

      //Filter Token by Status in BlackList
      if (
        token.status !== undefined &&
        TOKEN_STATUS_BLACKLIST.includes(token.status)
      ) {
        return false;
      }

      //Filter TODO

      return true;
    }) || []
  );
};

export const transformTokens = (tokenList: any[]): Token[] => {
  return (
    tokenList.map((token) => {
      const {
        decimals,
        symbol,
        status,
        tokenAddress,
        bridgeAddress,
        tokenID,
        bridgeContractAddress,
        type,
        name,
        logo,
        network,
        isNativeBridge,
        order,
      } = token;

      const _tokenAddress = {
        ...tokenAddress,
        [network]: tokenID,
      };

      const _bridgeAddress = {
        ...bridgeAddress,
        [network]: bridgeContractAddress,
      };

      const mapperToken: Token = {
        network,
        decimals,
        symbol: symbol ? symbol.toUpperCase() : symbol,
        status,
        tokenAddress: _tokenAddress,
        bridgeAddress: _bridgeAddress,
        tcTokenID: tokenID,
        tokenID: tokenID,
        tcBridgeID: bridgeContractAddress,
        type: type ? (type.toUpperCase() as TokenType) : 'UNKNOW',
        logo,
        name,
        id: `${network}-${tokenID || bridgeContractAddress}-${symbol}`,
        isNativeBridge,
        order: order,
        bridgeContractAddress: _bridgeAddress,
      };

      return mapperToken;
    }) || []
  );
};
