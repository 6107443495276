import { createSlice } from '@reduxjs/toolkit';

import { Web3ContextType } from '@web3-react/core';
import { actionCreators } from './actions';

export type Web3ReactState = {
  web3Data?: Web3ContextType;
};

export const initialState: Web3ReactState = {
  web3Data: undefined,
};

const web3ReactSlice = createSlice({
  name: 'web3React',
  initialState,
  reducers: {
    ...actionCreators,
  },
});

export const web3ReactActionCreators = web3ReactSlice.actions;
export default web3ReactSlice.reducer;
