const WHITE_COLOR = '#FFFFFF';
const BLACK_COLOR = '#000000';

export const Colors = {
  white: WHITE_COLOR,
  black: BLACK_COLOR,
  tranpsarent: 'transparent',

  primary: {
    color: WHITE_COLOR,
    background: BLACK_COLOR,
  },

  bg1: '#1B1E26',
  bg2: '#404040',
  bg3: '#333333',
  bg4: '#5b5b5b',
  bg5: '#131419',

  text1: '#A1A8B8',
  text2: '#BFBFBF',

  border1: '#353945',
  border2: '#F2F4F5',
  border3: '#5b5b5b',
  border4: '#494b51',

  success: '#18CF8E',

  warning: '#FF7E21',

  error: '#FF4000',
  error1: '#ff5747',

  btn1: '#1A73E8',
  btn2: '#404040',
  btnBg: '#8759F2',
};

export const darkTheme = {
  ...Colors,
  primary: {
    color: WHITE_COLOR,
    background: BLACK_COLOR,
  },
};

export const lightTheme = {
  ...Colors,
  primary: {
    color: BLACK_COLOR,
    background: WHITE_COLOR,
  },
};

export type ColorsType = typeof Colors;
export type ColorsKey = keyof typeof Colors;
