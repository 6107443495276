import { TC_BRIDGES_API_URL } from '@/configs';
import {
  GenerateDepositPayload,
  GenerateDepositData,
} from '@/interfaces/api/deposit';
import { camelCaseKeys } from '@trustless-computer/dapp-core';
import createAxiosInstance from './http-client';

const apiClient = createAxiosInstance({ baseURL: TC_BRIDGES_API_URL });
const API_PATH = '/api/generate-deposit-address';

export const generateDepositDataAPI = async (
  payload: GenerateDepositPayload,
): Promise<GenerateDepositData> => {
  try {
    console.log('[generateDepositDataAPI] payload: ', payload);
    const response = await apiClient.post(`${API_PATH}`, payload);
    console.log('[generateDepositDataAPI] response: ', response);
    return Object(camelCaseKeys(response));
  } catch (error: any) {
    console.log('[generateDepositDataAPI] error: ', error);
    throw error;
  }
};
