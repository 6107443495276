import { getTokenListAPI } from '@/services/tokens';
import { CaseReducer, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { TokenState } from './reducer';
import { Token } from './types';
import { filterManager } from './filterManager';

export const PREDIX = 'TOKENS';

const setTokens: CaseReducer<TokenState, PayloadAction<Token[]>> = (
  state,
  action,
) => {
  state.tokenList = action.payload;
};

const fetchToken = createAsyncThunk(`${PREDIX}/fetchToken`, async () => {
  const tokenList = await getTokenListAPI();
  return filterManager([...tokenList]) || [];
});

const actionCreators = {
  setTokens,
};

// Export Pure Actions
export { actionCreators };
// Export Async Actions
export { fetchToken };
