export enum NETWORK_SUPPORTING {
  BITCOIN = 'bitcoin',
  ETHEREUM = 'ethereum',
  ORDINALS = 'ordinals',
  TRUSTLESS_LAYER1 = 'trustlessLayer1',
  TRUSTLESS_LAYER2 = 'trustlessLayer2',
  NAKA = 'naka',
  SOLANA = 'solana',
}

export const SYMBOL_BY_NETWORK_NAME = {
  [NETWORK_SUPPORTING.BITCOIN]: 'BTC',
  [NETWORK_SUPPORTING.ETHEREUM]: 'ETH',
  [NETWORK_SUPPORTING.ORDINALS]: 'ORD',
  [NETWORK_SUPPORTING.TRUSTLESS_LAYER1]: 'BVM',
  [NETWORK_SUPPORTING.TRUSTLESS_LAYER2]: 'NOS',
  [NETWORK_SUPPORTING.NAKA]: 'NAKA',
  [NETWORK_SUPPORTING.SOLANA]: 'SOL',
};

export const isTCNetwork = (network?: string) => {
  if (!network) return false;
  return network === NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
};

export const isNOSNetwork = (network?: string) => {
  if (!network) return false;
  return network === NETWORK_SUPPORTING.TRUSTLESS_LAYER2;
};

export const isLayer2 = (network?: string) => {
  if (!network) return false;
  return (
    network !== NETWORK_SUPPORTING.BITCOIN &&
    network !== NETWORK_SUPPORTING.ETHEREUM &&
    network !== NETWORK_SUPPORTING.ORDINALS &&
    network !== NETWORK_SUPPORTING.SOLANA &&
    network !== NETWORK_SUPPORTING.TRUSTLESS_LAYER1
  );
};

export const isOutChain = (network?: string) => {
  if (!network) return false;
  return (
    network === NETWORK_SUPPORTING.BITCOIN ||
    network === NETWORK_SUPPORTING.ETHEREUM ||
    network === NETWORK_SUPPORTING.ORDINALS ||
    network === NETWORK_SUPPORTING.SOLANA
  );
};
