import { ColorsKey } from '@/theme/colors';
import { Text as TextRebass, TextProps as TextPropsOriginal } from 'rebass';
import styled from 'styled-components';

declare const FONT_SZIES: [
  6,
  8,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
  34,
  38,
  42,
  48,
  54,
  60,
];
declare type FontSizeValues = typeof FONT_SZIES[number];
declare type FontWeightValues = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

type TextProps = Omit<
  TextPropsOriginal,
  'css' | 'colors' | 'fontWeight' | 'fontSize'
>;

const DefaultText = styled(TextRebass)<{
  color: string | undefined;
}>`
  color: ${({ color, theme }) => (color ? (theme as any).colors[color] : 'white')};
`;

interface TextExternalProps extends TextProps {
  color?: ColorsKey | undefined;
  fontWeight?: FontWeightValues;
  fontSize?: FontSizeValues;
}

const Text = (props: TextExternalProps) => {
  const { color = 'white', fontWeight = 500, fontSize = 16 } = props;
  const newProps = {
    ...props,
    color,
    fontWeight,
    fontSize,
  };
  return <DefaultText {...newProps} />;
};

export default Text;
