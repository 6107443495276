/* eslint-disable */
import { API_URL } from '@/configs';
import {
  IGenerativeNonceMessagePayload,
  IVerifyNonceMessagePayload,
} from '@/interfaces/api/auth';
import { swrFetcher } from '@/utils/swr';

const API_PATH = API_URL + '/api/auth';

export const generateNonceMessage = (
  payload: IGenerativeNonceMessagePayload,
): Promise<string> =>
  swrFetcher(`${API_PATH}/nonce`, {
    method: 'POST',
    data: payload,
    error: 'Failed to generate nonce message',
  });

export const verifyNonceMessage = (
  payload: IVerifyNonceMessagePayload,
): Promise<any> => {
  return swrFetcher(`${API_PATH}/nonce/verify`, {
    method: 'POST',
    data: payload,
    error: 'Failed to verify nonce message',
  });
};
