import { getTheme } from '@/theme';
import React, { useMemo } from 'react';
import {
  createGlobalStyle,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components';

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = true;
  const themeObject = useMemo(() => getTheme(darkMode), [darkMode]);
  return (
    <StyledComponentsThemeProvider theme={themeObject}>
      {children}
    </StyledComponentsThemeProvider>
  );
}

export const ThemedGlobalStyle = createGlobalStyle`

  html  {
    
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.black};

    font-family: "TitilliumWeb";
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    @media screen and (min-width: 1920px) {
      font-size: 18px;
    }

    @media screen and (min-width: 2048px) {
      font-size: 20px;
    }
    
    ::-webkit-scrollbar {
      display: block;
      width: 0;
    }

    h3 {
      font-size: ${({ theme }) => theme.fontSizes.h3};
      line-height: ${({ theme }) => theme.lineHeight.h3};
    }

    h5 {
      font-size: ${({ theme }) => theme.fontSizes.h5};
      line-height: ${({ theme }) => theme.lineHeight.h5};
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: ${({ theme }) => theme.colors.white};
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover{
        color: inherit;
        text-decoration: underline;
      }
    }
    
    input {
      :-webkit-autofill {
        -webkit-background-clip: text;
        -webkit-text-fill-color: white !important;
      }
    }

    .hover {
      :hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }

    .animation-rotate {
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(180deg);
        }
      }
      animation: spin ${0.6}s linear;
    }
  }

  summary::-webkit-details-marker {
    display:none;
  }
`;
