export const ROUTE_PATH = {
  NOT_FOUND: '/404',
  HOME: '/',
  DAPPS: '/dapps',
  COLLECTION: '/collection',
  INSCRIPTION: '/inscription',
  FAUCET: '/faucet',
  USE_TRUSTLESS: '/use-trustless-computer',
  WALLET: '/wallet',
  CONNECT_WALLET: '/connect-wallet',
  test: '/test',

  //BTC link
  NAKAMOTO: '/nakamoto',
  BITCOIN: '/bitcoin',
  BTC: '/btc',

  //ETH link
  VITALIZ: '/vitaliz',
  ETHEREUM: '/ethereum',
  eth: '/eth',
};
