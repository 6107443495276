import BigNumber from 'bignumber.js';

const toNumber = (text: string | number): number => {
  return new BigNumber(text).toNumber();
};

const toBigNumberObject = (amount: string | number, decimals = 18): BigNumber => {
  const indexNumberBN = new BigNumber(10).pow(decimals).toNumber();
  const amountBN = new BigNumber(amount).multipliedBy(indexNumberBN);
  return amountBN;
};

//Ex: amount 0.8, decimal = 8 (case USDT on Ethereum Network)
//Result => 800000 (string)
//Ex: amount 12, decimal = 18
//Result => 120000000000000000 (string)
const toBigNumberNonRound = (amount: string | number, decimals = 18): string => {
  const indexNumberBN = new BigNumber(10).pow(decimals).toNumber();
  const amountBN = new BigNumber(amount);
  const amountStr = amountBN.multipliedBy(indexNumberBN).toFixed();
  return amountStr;
};

const convertUtils = {
  toNumber,
  toBigNumberNonRound,
  toBigNumberObject,
};

export default convertUtils;
