import { Modal } from 'react-bootstrap';
import { ModalStyled, Container } from '@components/AccountErrorModal/styled';
import Text from '@components/Text';
import { TC_WEB_URL } from '@/configs';

type Props = {
  isShow: boolean;
  onHide?: any;
};

function AccountErrorModal(props: Props) {
  const { isShow, onHide } = props;

  return (
    <ModalStyled centered show={isShow} keyboard={false} onHide={onHide}>
      <Modal.Header closeButton color="white" closeVariant="white">
        <Modal.Title style={{ color: 'white' }}>Connected error</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Text>
            The Metamask associated with the{' '}
            <a href={TC_WEB_URL} target="_blank" className="wallet-link">
              Trustless Wallet
            </a>{' '}
            differs from the address associated with the Trustless Bridge. Please
            disconnect and use the same Metamask address for both of them.
          </Text>
        </Container>
      </Modal.Body>
    </ModalStyled>
  );
}

export default AccountErrorModal;
