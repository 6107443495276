import { GenerateDepositData } from '@/interfaces/api/deposit';
import { EstimateWithdrawFeeData } from '@/interfaces/api/withdraw';
import { Token } from '@/state/tokens/types';
import { createSlice } from '@reduxjs/toolkit';
import {
  PREDIX,
  actionCreators,
  fetchEstimateWithdrawData,
  fetchGenerateDepositData,
} from './actions';
import { FormType } from './types';
import { NetworkName } from '@/state/network/types';

export type FormBridgeState = {
  fromNetworkSelected?: NetworkName;
  fromTokenSelected?: Token;
  fromInputAmount?: string;

  toNetworkSelected?: NetworkName;
  toTokenSelected?: Token;
  toInputAmount?: string;
  toInputAddress?: string;

  isFetching?: boolean;
  isLoading?: boolean;

  error?: any;

  formType?: FormType;

  generateDepositData?: GenerateDepositData;
  generateDepositDataLoading?: boolean;
  generateDepositDataFetched: boolean;
  generateDepositError?: any;

  estimateWithdrawData?: EstimateWithdrawFeeData;
  estimateWithdrawLoading?: boolean;
  estimateWithdrawFetched: boolean;
  estimateWithdrawError?: any;

  fromBalance?: string;
  toBalance?: string;

  allowanceAmount?: string; //nano , need divide decimals based on token selected!

  formInstance?: any;

  feeBurnNativeToken?: string;
};

export const initialState: FormBridgeState = {
  formType: undefined,
  generateDepositDataFetched: false,
  estimateWithdrawFetched: false,
};

const FormBridgeSlice = createSlice({
  name: PREDIX,
  initialState,
  reducers: {
    ...actionCreators,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenerateDepositData.pending, (state) => {
        state.generateDepositDataLoading = true;
        state.generateDepositDataFetched = false;
      })
      .addCase(fetchGenerateDepositData.fulfilled, (state, action) => {
        state.generateDepositDataLoading = false;
        state.generateDepositDataFetched = true;
        state.generateDepositData = action.payload;
      })
      .addCase(fetchGenerateDepositData.rejected, (state, action) => {
        state.generateDepositDataLoading = false;
        state.generateDepositDataFetched = true;
        state.generateDepositData = undefined;
        state.generateDepositError = action.error;
      })

      .addCase(fetchEstimateWithdrawData.pending, (state) => {
        state.estimateWithdrawLoading = true;
      })
      .addCase(fetchEstimateWithdrawData.fulfilled, (state, action) => {
        state.estimateWithdrawLoading = false;
        state.estimateWithdrawData = action.payload;
      })
      .addCase(fetchEstimateWithdrawData.rejected, (state, action) => {
        state.estimateWithdrawLoading = false;
        state.estimateWithdrawData = undefined;
        state.estimateWithdrawError = action.error;
      });
  },
});

export const formBridgeActions = FormBridgeSlice.actions;
export default FormBridgeSlice.reducer;
