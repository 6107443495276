import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { Web3ContextType } from '@web3-react/core';
import { Web3ReactState } from './reducer';

const setWeb3Data: CaseReducer<Web3ReactState, PayloadAction<Web3ContextType>> = (
  state,
  action,
) => {
  state.web3Data = action.payload;
};

const actionCreators = {
  setWeb3Data,
};

export { actionCreators };
