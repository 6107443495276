import type { JsonRpcProvider } from '@ethersproject/providers';
import { useMemo } from 'react';
import { ethers } from 'ethers';
import { TC_NETWORK_RPC } from '@/configs';

function useProvider<T extends JsonRpcProvider = JsonRpcProvider>(
  rpc = TC_NETWORK_RPC,
): T | undefined {
  return useMemo(() => {
    try {
      return new ethers.providers.JsonRpcProvider(rpc);
    } catch (error) {
      return undefined;
    }
  }, []) as T;
}

export default useProvider;
