/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { isProduction } from '@/utils/commons';

// App configs
export const APP_ENV: string = process.env.NEXT_PUBLIC_MODE!;
export const API_URL: string = process.env.NEXT_PUBLIC_API_URL!;
export const TC_BRIDGES_API_URL: string =
  process.env.NEXT_PUBLIC_API_TC_BRIDGES_URL!;
export const TC_NETWORK_RPC: string = process.env.NEXT_PUBLIC_TC_NETWORK_RPC!;
export const TC_LAYER2_NETWORK_RPC: string =
  process.env.NEXT_PUBLIC_TC_LAYER2_NETWORK_RPC!;

export const CDN_URL: string = process.env.NEXT_PUBLIC_CDN_URL!;
export const SEQUENCER_API_URL: string = process.env.NEXT_PUBLIC_SEQUENCER_API_URL!;

// Contract configs

export const TC_WEB_URL: string = process.env.NEXT_PUBLIC_TC_WEB_URL!;

export const API_BLOCKSTREAM: string = process.env.NEXT_PUBLIC_API_BLOCKSTREAM!;

export const CDN_ICON_URL: string = process.env.NEXT_PUBLIC_CDN_ICON_URL!;

// Symbol = XXX => Remove
export const TOKEN_BLACKLIST: string[] = (isProduction() ? [] : []).map(
  (item: string) => item.toUpperCase(),
);
export const TOKEN_WHITELIST: string[] = (isProduction() ? [] : []).map(
  (item: string) => item.toUpperCase(),
);

// Status = 0 => Remove
export const TOKEN_STATUS_BLACKLIST: number[] = isProduction() ? [0] : [];
export const TOKEN_STATUS_WHITELIST: number[] = isProduction() ? [] : [];

// tcLayer = 2 => Remove
export const TOKEN_LAYER_BLACKLIST: number[] = isProduction() ? [] : [];
export const TOKEN_LAYER_WHITELIST: number[] = isProduction() ? [] : [];
