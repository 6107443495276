import { NETWORK_SUPPORTING } from '@/constants/network';

export const Fields = {
  AMOUNT: 'amount',
  ADDRESS: 'address',
};

export const FormInitialValues = {
  [Fields.AMOUNT]: '',
  [Fields.ADDRESS]: '',
};

export const DEPOSIT_SUPPORTING_NETWORKS = [
  NETWORK_SUPPORTING.BITCOIN,
  NETWORK_SUPPORTING.ORDINALS,
  NETWORK_SUPPORTING.ETHEREUM,
  NETWORK_SUPPORTING.SOLANA,
] as string[];

export const WAITING_APPROVAL_CONFIRMATION_TIMEOUT = 20; //20s
