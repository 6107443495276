import convertUtils from '@/utils/convert';
import { logger } from '@/utils/logger';
import BigNumber from 'bignumber.js';
import { approveTxsMap, removeApproveTxsMap } from './FormBridge.localStorage';
import { FormType } from '@/containers/FormBridge/state/types';
import { Token, TokenType } from '@state/tokens/types';
import { NetworkName } from '@/state/network/types';
import { NETWORK_SUPPORTING } from '@/constants/network';
import { DEPOSIT_SUPPORTING_NETWORKS } from './FormBridge.constants';

type CheckAllowanceAmountParams = {
  amount: string;
  decimals?: number;
  allowanceAmount?: string;
};

export const checkAllowanceAmount = async (params: CheckAllowanceAmountParams) => {
  const { amount, decimals = 18, allowanceAmount = '0.0' } = params;

  const amountInputBN = convertUtils.toBigNumberObject(amount, decimals);
  const allowanceAmountBN = new BigNumber(allowanceAmount);

  const result = amountInputBN.gt(allowanceAmountBN);

  return result;
};

type CurrentAmountWithAllowanceAmountParams = {
  amountInput: string;
  allowanceAmount?: string;
  decimals?: number;
};

// return true: amountInput >= allowanceAmount
// return false: amountInput < allowanceAmount

export const compareCurrentAmountWithAllownAmount = async (
  params: CurrentAmountWithAllowanceAmountParams,
) => {
  const { amountInput, decimals = 18, allowanceAmount = '0.0' } = params;

  // Amount Input BigNumber
  const amountInputBN = convertUtils.toBigNumberObject(amountInput, decimals);

  // Allowance Amount BigNumber
  const allowanceAmountBN = new BigNumber(allowanceAmount);

  // Compare
  const result = amountInputBN.gte(allowanceAmountBN);

  return result;
};

type CheckApproveParams = {
  amountInput: string;
  allowanceAmount?: string;
  decimals?: number;
  keyDefined: string;
  type: TokenType;
};

/**
 * Add opacity information to a hex color
 * @param amountInput current amount input that user have been typed => string
 * @param allowanceAmount allowanceAmount => nano
 * @param decimal default = 18
 * @param keyDefined key = [tcAddress]-[tokenAddress]
 * @returns 1 => Need Aprove
 * @returns 0 => Not yet
 */

export const checkNeedApproveOnL1 = (params: CheckApproveParams) => {
  const {
    amountInput: currentInputAmount,
    allowanceAmount,
    decimals = 18,
    keyDefined,
    type,
  } = params;

  if (type === 'NATIVE') return false;

  logger('[checkNeedApprove] params: ', params);

  const txApproveDataLocal = approveTxsMap[keyDefined];

  // Not Exist TxApproveData in LocalStorage by KeyDefined
  if (!txApproveDataLocal) {
    // AllowanceAmount from chain = undefined | zero => Need Approve
    if (!allowanceAmount || new BigNumber(allowanceAmount).isZero()) {
      logger(
        '[checkNeedApprove] CASE 1 [FAILED]: txApproveDataLocal = undefined, allowanceAmount = undefined | zero',
      );
      return 1;
    } else {
      // Current Input BigNumber
      const currentInputBN = convertUtils.toBigNumberObject(
        currentInputAmount,
        decimals,
      );

      // Allowance Amount BigNumber
      const allowanceAmountBN = new BigNumber(allowanceAmount);

      if (currentInputBN.gt(allowanceAmountBN)) {
        logger(
          '[checkNeedApprove] CASE 2 [FAILED]: txApproveDataLocal = undefined, currentInput > allowanceAmount',
        );
        return 1;
      } else {
        logger(
          '[checkNeedApprove] CASE 3 [OK]: txApproveDataLocal = undefined, currentInput <= allowanceAmount',
        );
        return 0;
      }
    }
  } else {
    const { allowanceAmount: allowanceAmountBefore } = txApproveDataLocal;
    // Allowance Amount BigNumber
    const allowanceAmountBeforeBN = new BigNumber(allowanceAmountBefore || 0);

    // Current Input BigNumber
    const currentInputBN = convertUtils.toBigNumberObject(
      currentInputAmount,
      decimals,
    );

    // delete local, check current allowance
    if (!new BigNumber(allowanceAmount || 0).isZero()) {
      removeApproveTxsMap(keyDefined);
      const isNeededApprove = new BigNumber(allowanceAmount || 0).lt(currentInputBN);
      logger('[checkNeedApprove] CASE 4 [FAILED]: remove local transaction ', {
        isNeededApprove,
        allowanceAmount,
        currentInput: currentInputBN.toFixed(),
      });
      return isNeededApprove ? 1 : 0;
    }

    if (currentInputBN.gt(allowanceAmountBeforeBN)) {
      logger(
        '[checkNeedApprove] CASE 5 [FAILED]: txApproveDataLocal [DATA], currentInput > allowanceAmountBefore',
      );
      return 1;
    } else {
      logger(
        '[checkNeedApprove] CASE 6 [OK]: txApproveDataLocal [DATA], currentInput <= allowanceAmountBefore',
      );
      return 0;
    }
  }
};

type CheckApproveOnL2Params = {
  amountInput: string;
  allowanceAmount?: string;
  decimals?: number;
};

// Return true => Need Approve
// Return false => Not Yet

export const checkNeedApproveOnL2 = (params: CheckApproveOnL2Params) => {
  const { amountInput: currentInputAmount, allowanceAmount, decimals = 18 } = params;

  logger('[checkNeedApproveOnL2] paras: ', params);

  // AllowanceAmount from chain = undefiend | zero => Need Approve
  if (!allowanceAmount || new BigNumber(allowanceAmount).isZero()) {
    logger(
      '[checkNeedApproveOnL2] CASE 1 [FAILED]: allowanceAmount = undefiend | zero',
    );
    return true;
  } else {
    // Current Input BigNumber
    const currentInputBN = convertUtils.toBigNumberObject(
      currentInputAmount,
      decimals,
    );

    // Allowance Amount BigNumber
    const allowanceAmountBN = new BigNumber(allowanceAmount);

    if (currentInputBN.gt(allowanceAmountBN)) {
      logger(
        '[checkNeedApproveOnL2] CASE 2 [FAILED]: currentInput > allowanceAmount',
      );
      return true;
    } else {
      logger('[checkNeedApproveOnL2] CASE 3 [OK]: currentInput <= allowanceAmount');
      return false;
    }
  }
};

export const getFormTypeByNetwork = (fromNetwork: NetworkName): FormType => {
  return isDeposit(fromNetwork) ? 'Deposit' : 'Withdraw';
};

// [DEPOSIT] Definition
// If FromNetwork: BTC, ETH, Ordinals => L1 || L2 (includes NOS and L2 another)
// Otherwise: => [WITHDRAW]
export const isDeposit = (fromNetwork?: NetworkName): boolean => {
  if (!fromNetwork) return false;
  return DEPOSIT_SUPPORTING_NETWORKS.includes(fromNetwork);
};

export const getReceiverTokenAddress = ({
  network,
  token,
  formType,
}: {
  network?: NetworkName;
  token?: Token;
  formType?: FormType;
}) => {
  if (formType === 'Withdraw') {
    return token?.tcTokenID;
  }
  let tokenAddress: string | undefined = undefined;
  if (network) {
    tokenAddress = token?.tokenAddress[network];
  }
  return tokenAddress;
};

export const getBridgeAddress = ({
  network,
  token,
}: {
  network?: NetworkName;
  token?: Token;
}) => {
  let bridgeAddress: string | undefined = undefined;
  if (network) {
    bridgeAddress = token?.bridgeAddress[network];
  }
  return bridgeAddress;
};

export const isLayer1Network = (network?: NetworkName) =>
  network === NETWORK_SUPPORTING.TRUSTLESS_LAYER1;
